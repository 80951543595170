import { useAuth0 } from '@auth0/auth0-react'
import { TemplateType, useTemplatesLazyQuery } from '@generated/graphql'
import StorageHelper from '@modules/storage'
import { currentNetworkIdSelector } from '@store/slices/networks/selectors'
import { setTemplates, setTemplatesLoading } from '@store/slices/templates'
import { templatesSelector } from '@store/slices/templates/selectors'
import { useAppDispatch, useAppSelector } from '@store/typedHooks'
import { Modal } from 'antd'
import { useEffect } from 'react'

const CANONICAL_NETWORK_ID = 'rec4tMkSLWxeAanU9'

export function useFetchTemplates(types: TemplateType[]) {
  const { isAuthenticated } = useAuth0()
  const dispatch = useAppDispatch()
  const networkId = useAppSelector(currentNetworkIdSelector)
  const templates = useAppSelector(templatesSelector)

  useEffect(() => {
    if (types?.[0]) {
      StorageHelper.setItem('templateType', types?.[0])
    }
  }, [types?.[0]])

  const [queryTemplates] = useTemplatesLazyQuery({
    onCompleted: ({ templates }) => {
      dispatch(setTemplates(templates))
    },
    onError: error => {
      Modal.error({
        title: 'Templates failed to load',
        content: 'Please reload page',
      })
      console.error('Unable to load templates', error)
    },
  })

  useEffect(() => {
    if (!isAuthenticated || !types?.length) {
      return
    }

    dispatch(setTemplatesLoading(true))
    queryTemplates({
      variables: {
        where: {
          type: {
            in: types,
          },
          OR: [
            {
              networkId: {
                equals: networkId,
              },
            },
            {
              networkId: {
                equals: null,
              },
            },
          ],
        },
      },
    })
  }, [networkId, isAuthenticated, queryTemplates, dispatch, types])

  useEffect(() => {
    // temporary solution to unblock Network creation
    if (
      types?.[0] === TemplateType.Network &&
      !networkId &&
      !templates?.find(
        template => template.network?.id === CANONICAL_NETWORK_ID,
      )
    ) {
      console.log('fetching templates')
      queryTemplates({
        variables: {
          where: {
            type: {
              in: types,
            },
            OR: [
              {
                networkId: {
                  equals: CANONICAL_NETWORK_ID,
                },
              },
              {
                networkId: {
                  equals: null,
                },
              },
            ],
          },
        },
      })
    }
  }, [networkId, isAuthenticated, queryTemplates, dispatch, types, templates])
}
